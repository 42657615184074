.gutter-box {
    display: none;
    padding: 8px 0;
    background: #00a0e9;
  }
.category-name { 
  margin-top: 0px; 
  margin-bottom: 16px; 
  color: #ffffff;
  text-transform: uppercase; 
}
.fixture-card {
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: space-between;
  align-items: center;
  background: #e5e5e5;
  /* border: 2px solid #e5e5e5; */
  border-radius: 2%;
  color: #464343;
  margin: 5px;
  border-radius: 10px;
  border-left: 5px solid #95100d;
}
.team-name {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* font-weight: bold; */
  font-size: 16px;
}
.fixture-image {
  max-width: 20px;
  margin-left: 2px;
  margin-right: 2px;
}
.fixture-card:hover {
  background-color: #38383f;
  transition: background-color .7s ease;
}
.fixture-card:hover .category,
.fixture-card:hover .team-name,
.fixture-card:hover .verses{
  color: #f5f1f1;
  transition: color 0.3s ease;
}
.ant-card-head {
  background-color: #e1dada !important;
  min-height: 60px !important;
}
.fixture-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #464343;
  text-transform: capitalize;
  transition: transform 0.2s;
}
.fixture-link:hover {
  color: #360808;
  text-transform: capitalize;
  /* transform: scale(1.03); */
}
.flex-display {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.match-time {
  border: 2px solid #e5e5e5;
  padding-right: 5px;
  display: inline-block;
  border-radius: 4%;
  padding-left: 6px;
  background: #d1d1d1;
  color: #464343
}
.live-match-time {
  border: 2px solid #a32e2e;
  padding-right: 5px;
  display: inline-block;
  border-radius: 10%;
  padding-left: 6px;
  background: #a71b1b;
  color: #f0e9e9
}
.category {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 800;
  color: rgb(149, 16, 13);
  font-size: 15px;
}
.dash {
  width: 4px;
  height: 14px;
  margin: 0 10px;
  background: #34c7c7;
}
.verses {
  display: flex;
  padding-right: 6px;
  padding-left: 6px;
  justify-content: center;
  text-transform: lowercase;
  font-size: 16px;
}
.more:hover {
  color: #5a0808  !important;
  text-decoration: underline;
}
/* Your CSS file or in a style block */
@media (max-width: 768px) {
  .fixture-card {
    padding-left: 5px;
    padding-right: 5px;
  }
  .ant-col-16 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-12 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-6 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-4 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-card-body {
    padding: 0px;
  }
  .flex-display {
    font-size: 11px;
  }
  .time-button {
    font-size: 12px !important;
  }
  .match-time {
    font-size: 11px;
  }
  .live-match-time {
    font-size: 11px;
  }
  .team-name{
    justify-content: flex-start;
  }
  .fixture-image {
    max-width: 16px;
  }
}
